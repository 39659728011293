import {
  IconExperiencesCamping,
  IconExperiencesClimbing,
  IconExperiencesCycling,
  IconExperiencesFitness,
  IconExperiencesOutdoorBasics,
  IconExperiencesSnowsports,
  IconExperiencesTravel,
  IconExperiencesPaddling,
  IconExperiencesHiking,
} from '@rei/cedar';
import type { Activity, CategoryIcon } from '../../types/events-home.types.mjs';

export const activities: Activity[] = [
  {
    value: '0',
    programName: 'ALL',
    name: 'All activities',
  },
  {
    value: '164',
    programName: 'CAMPING_AND_HIKING',
    name: 'Camping & Hiking',
  },
  {
    value: '21',
    programName: 'CLIMBING',
    name: 'Climbing',
  },
  {
    value: '22',
    programName: 'CYCLING',
    name: 'Cycling',
  },
  {
    value: '167',
    programName: 'FITNESS',
    name: 'Fitness',
  },
  {
    value: '166',
    programName: 'OUTDOOR_SKILLS',
    name: 'Outdoor Skills',
  },
  {
    value: '26',
    programName: 'SNOWSPORTS',
    name: 'Snowsports',
  },
  {
    value: '84',
    programName: 'STEWARDSHIP',
    name: 'Stewardship',
  },
  {
    value: '82',
    programName: 'TRAVEL',
    name: 'Travel',
  },
  {
    value: '165',
    programName: 'WATERSPORTS',
    name: 'Watersports',
  },
];

export const distances: string[] = ['25', '50', '100', '150', '200'];

export const categoryIcons: CategoryIcon[] = [
  {
    icon: IconExperiencesCamping,
    link: {
      title: 'Camping & Hiking',
      href: '/events/a/camping-and-hiking',
    },
  },
  {
    icon: IconExperiencesClimbing,
    link: {
      title: 'Climbing',
      href: '/events/a/climbing',
    },
  },
  {
    icon: IconExperiencesCycling,
    link: {
      title: 'Cycling',
      href: '/events/a/cycling',
    },
  },
  {
    icon: IconExperiencesFitness,
    link: {
      title: 'Fitness',
      href: '/events/a/fitness',
    },
  },
  {
    icon: IconExperiencesHiking,
    link: {
      title: 'Outdoor Skills',
      href: '/events/a/outdoor-skills',
    },
  },
  {
    icon: IconExperiencesSnowsports,
    link: {
      title: 'Snowsports',
      href: '/events/a/snowsports',
    },
  },
  {
    icon: IconExperiencesOutdoorBasics,
    link: {
      title: 'Stewardship',
      href: '/events/a/stewardship',
    },
  },
  {
    icon: IconExperiencesTravel,
    link: {
      title: 'Travel',
      href: '/events/a/travel',
    },
  },
  {
    icon: IconExperiencesPaddling,
    link: {
      title: 'Watersports',
      href: '/events/a/watersports',
    },
  },
];
