<script lang="ts" setup>
/**
 * A component to show circulate images displayed in columns with text below
 * @displayName CircleBlurbs
 */
import { computed } from 'vue';
import { CdrText, CdrGrid } from '@rei/cedar';
import BenefitComponent from './BenefitComponent.vue';
import { type Benefit } from '../../types/events-home.types.mjs';
import getAnchor from '../../common/anchor.mjs';
import getDataUi from '../../common/getDataUi.mjs';

defineOptions({ name: 'AboutCoopExperiences', inheritAttrs: false });

export interface AboutCoopExperiencesProps {
  heading: string;
  benefits: Benefit[];
  dataUi?: string;
}

const props = withDefaults(defineProps<AboutCoopExperiencesProps>(), {
  dataUi: 'about-coop-experiences',
});

const id = computed(() => getAnchor(props.heading));
const dataUi = computed(() => ({
  heading: getDataUi(props.dataUi, 'heading'),
  grid: getDataUi(props.dataUi, 'grid'),
  benefit: getDataUi(props.dataUi, 'benefit'),
}));
</script>

<template>
  <section
    :id="id"
    class="about-coop-experiences"
    :data-ui="props.dataUi"
  >
    <CdrText
      v-if="props.heading"
      class="about-coop-experiences__heading"
      tag="h2"
      :data-ui="dataUi.heading"
    >
      {{ props.heading }}
    </CdrText>
    <CdrGrid
      class="about-coop-experiences__grid"
      tag="ul"
      :data-ui="dataUi.grid"
    >
      <BenefitComponent
        v-for="(benefit, index) in props.benefits"
        :key="`${benefit.heading}${index}`"
        :heading="benefit.heading"
        :description="benefit.description"
        :image="benefit.image"
        :data-ui="dataUi.benefit"
      />
    </CdrGrid>
  </section>
</template>

<style lang="scss" scoped>
@import '../../../../../node_modules/@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

.about-coop-experiences {
  &__heading {
    @include cdr-text-heading-sans-600;
    margin-bottom: $cdr-space-two-x;
  }

  &__grid {
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    gap: $cdr-space-four-x;

    & > * {
      margin-bottom: $cdr-space-one-x;
    }

    @media (max-width: 775px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
