<script lang="ts" setup>
import { computed } from 'vue';
import { CdrText } from '@rei/cedar';
import ResponsiveImage from '../global/contentstack/ResponsiveImage.vue';
import { type Image } from '../../types/contentstack.types';
import getDataUi from '../../common/getDataUi.mjs';

defineOptions({ name: 'BenefitComponent', inheritAttrs: false });

export interface BenefitComponentProps {
  heading: string;
  description: string;
  image: Image;
  tag?: string;
  dataUi?: string;
}

const props = withDefaults(defineProps<BenefitComponentProps>(), {
  tag: 'li',
  dataUi: 'benefit',
});

const imageSizes = computed(() => ({
  xs: '160px',
  sm: '160px',
  md: '160px',
  lg: '160px',
}));
const dataUi = computed(() => ({
  heading: getDataUi(props.dataUi, 'heading'),
  description: getDataUi(props.dataUi, 'description'),
  image: getDataUi(props.dataUi, 'image'),
}));
</script>

<template>
  <component
    :is="props.tag"
    class="benefit"
    :title="props.heading"
    :description="props.description"
    :data-ui="props.dataUi"
  >
    <ResponsiveImage
      container-class="benefit__img"
      :image="props.image"
      :sizes="imageSizes"
      :data-ui="dataUi.image"
    />
    <CdrText
      v-if="props.heading"
      class="benefit__heading"
      tag="h3"
      :data-ui="dataUi.heading"
    >
      {{ props.heading }}
    </CdrText>
    <CdrText
      class="benefit__description"
      :data-ui="dataUi.description"
    >
      {{ props.description }}
    </CdrText>
  </component>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

.benefit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    display: block;
    width: 160px;
    --responsive-image-height: 160px;
    border-radius: 100%;
    overflow: hidden;
  }

  &__heading {
    @include cdr-text-heading-sans-500;
    margin: $cdr-space-one-x 0 $cdr-space-half-x 0;
  }
}
</style>
