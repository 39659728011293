<script lang="ts" setup>
/**
 * A component to show cards displayed in columns.
 * Each card consists of an image, headline, and text.
 * @displayName SimpleCards
 */
import { computed } from 'vue';
import { CdrText, CdrGrid } from '@rei/cedar';
import FeaturedExperience from './FeaturedExperience.vue';
import getAnchor from '../../common/anchor.mjs';
import getDataUi from '../../common/getDataUi.mjs';
import { type FeaturedExperience as FeaturedExperienceType } from '../../types/events-home.types.mjs';

defineOptions({ name: 'FeaturedExperiences', inheritAttrs: false });

export interface FeaturedExperiencesProps {
  heading: string;
  experiences: FeaturedExperienceType[];
  dataUi?: string;
}

const props = withDefaults(defineProps<FeaturedExperiencesProps>(), {
  dataUi: 'featured-experiences',
});

const id = computed(() => getAnchor(props.heading));
const dataUi = computed(() => ({
  heading: getDataUi(props.dataUi, 'heading'),
  grid: getDataUi(props.dataUi, 'grid'),
}));
</script>

<template>
  <section
    :id="id"
    class="featured-experiences"
    :data-ui="props.dataUi"
  >
    <CdrText
      v-if="heading"
      class="featured-experiences__heading"
      tag="h2"
      :data-ui="dataUi.heading"
    >
      {{ heading }}
    </CdrText>
    <CdrGrid
      class="featured-experiences__grid"
      tag="ul"
      :data-ui="dataUi.grid"
    >
      <FeaturedExperience
        v-for="(experience, idx) in props.experiences"
        :key="`${experience.heading}${idx}`"
        :heading="experience.heading"
        :description="experience.description"
        :image="experience.image"
        :link="experience.link"
      />
    </CdrGrid>
  </section>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

.featured-experiences {
  &__heading {
    &#{&} {
      @include cdr-text-heading-sans-600;
      margin-bottom: $cdr-space-two-x;
    }
  }

  &__grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $cdr-space-one-x;

    & > * {
      margin-bottom: $cdr-space-one-x;
    }

    @include cdr-md-mq-down {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
