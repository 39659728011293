<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import AutocompleteInput from '@rei/autocomplete';
import { CdrSelect, CdrButton, CdrImg } from '@rei/cedar';
import { GeolocateButton, StatusMessages } from '@rei/geolocate-button';
import type { Image } from '../../types/contentstack.types';
import type { Analytics } from '../../types/shared.types.mjs';
import type { Activity, Place } from '../../types/events-home.types.mjs';
import getAnchor from '../../common/anchor.mjs';

defineOptions({ name: 'SearchBanner', inheritAttrs: false });

export interface SearchBannerProps {
  heading: string;
  description: string;
  image: Image;
  activities: Activity[];
  defaultActivity: number;
  distances: string[];
  defaultDistance: string;
  locationInputMaxLength?: number;
  location?: Place;
  dataUi?: string;
}

const props = withDefaults(defineProps<SearchBannerProps>(), {
  locationInputMaxLength: 75,
  location: undefined,
  dataUi: 'search-banner',
});

const autocompleteApi = '/events/geography/places/autocomplete';
const locationValidRegex = /^[^=+*!"{}[\]<>;$@/\\?%]*$/;
const geolocateButton = ref<InstanceType<typeof GeolocateButton>>(null);
const checkValidation = ref(false);
const location = ref(props.location);
const activity = ref(props.defaultActivity);
const distance = ref(props.defaultDistance);
const statusMessage = ref<string>();
const autocompleteFocused = ref<string>();

const id = computed(() => getAnchor(props.heading));
const wrapperStyles = computed(() => ({
  '--background-image-sm': `url('${props.image.renditions.original?.src}?im=Resize,width=730,height=1059')`,
  '--background-image-md': `url('${props.image.renditions.original?.src}?im=Resize,width=1455,height=970;AspectCrop=(3,2)')`,
}));
const locationValue = computed(() => {
  let loc;
  if (location.value && location.value.text) {
    loc = location.value.text;
  } else if (location.value && location.value.description) {
    loc = location.value.description;
  }
  return loc;
});
const isLocationValid = computed(() => {
  const locValue = locationValue.value ? locationValue.value.trim() : '';
  let isValid = false;
  if (locValue !== '' && locationValidRegex.test(locValue)) {
    isValid = true;
  }
  return isValid;
});

const analytics = inject<Analytics>('analytics');

const submitHandler = (e: Event) => {
  checkValidation.value = true;

  if (!isLocationValid.value) {
    e.preventDefault();
    analytics?.click('SearchBanner', 'search_go');
    statusMessage.value = 'invalid';
  } else {
    // configure location in local storage before submitting
    sessionStorage.setItem('location', JSON.stringify(location.value));
    // analytics event
    analytics?.click('SearchBanner', 'search_go', {
      opoLocation: locationValue.value,
      opoActivity: activity.value,
      opoWithinDistance: distance.value,
    });
    statusMessage.value = 'null';
  }
};
const onAutocompleteSelectedData = (selectedObj: Place) => {
  if (selectedObj) {
    location.value = selectedObj;
  }
};
const onAutocompleteChange = (inputValue: string) => {
  location.value = { text: inputValue };
};
const onAutocompleteFocus = (status: string) => {
  autocompleteFocused.value = status;
  statusMessage.value = undefined;
};
const onGeocodedGeolocation = (geolocation: string) => {
  location.value = { text: geolocation };
};
const onGeolocationStatus = (status: string) => {
  statusMessage.value = status;
};

watch(location, (newVal, oldVal) => {
  if (newVal !== null && newVal !== oldVal) {
    location.value = newVal;
  } else {
    location.value = undefined;
  }
});

onMounted(() => {
  const sessionLocationStr = sessionStorage.getItem('location');
  if (
    sessionLocationStr !== null &&
    window.performance &&
    window.performance.navigation.type === 2
  ) {
    const sessionLocation = JSON.parse(sessionLocationStr);
    location.value = sessionLocation;
  }
  // request geolocation on page load if value is not already set on load
  // example: when user navigates with back button to learn page
  if (
    sessionLocationStr === null ||
    (window.performance && window.performance.navigation.type !== 2)
  ) {
    setTimeout(() => geolocateButton.value?.getCurrentPosition(), 1000);
  }
});
</script>

<template>
  <section
    :id="id"
    :style="wrapperStyles"
    class="search-banner"
    :data-ui="dataUi"
  >
    <div
      class="container"
      data-ui="search-results-container"
    >
      <div class="row">
        <div class="col-xs-12">
          <div class="search-banner-copy">
            <h1
              class="search-banner__headline"
              data-ui="search-banner-headline"
              v-html="props.heading"
            />
            <p
              class="search-banner-body-text"
              data-ui="search-banner-description"
              v-html="props.description"
            />
          </div>
          <form
            class="search-banner-form"
            action="/events/search"
            method="get"
            @submit="submitHandler"
          >
            <div class="form-group search-banner-activity">
              <CdrSelect
                v-model="activity"
                data-ui="search-banner-activity"
                label="Activity"
                name="activity"
                background="secondary"
              >
                <option
                  v-for="(a, index) in props.activities"
                  :key="index"
                  :value="a.value"
                  :data-program-name="a.programName"
                  v-html="a.name"
                />
              </CdrSelect>
            </div>
            <input
              v-if="location && !location.placeId"
              :value="locationValue"
              data-ui="search-banner-location-input-hidden"
              type="hidden"
              name="location"
            />
            <input
              v-if="location && location.placeId"
              :value="location.placeId"
              type="hidden"
              name="geoLocationId"
            />
            <div :class="['form-group', 'search-banner-location']">
              <div
                class="input-group"
                aria-live="polite"
              >
                <AutocompleteInput
                  :model-value="locationValue"
                  :fetch-url="autocompleteApi"
                  :maxlength="locationInputMaxLength"
                  :delay="200"
                  autocomplete="street-address"
                  data-ui="search-banner-location-input"
                  label="Location"
                  match-property="description"
                  class="search-banner-location__autocomplete-wrapper"
                  placeholder="ZIP code or City, State"
                  @autocomplete-focus="onAutocompleteFocus"
                  @autocomplete-change="onAutocompleteChange"
                  @autocomplete-selected-data="onAutocompleteSelectedData"
                >
                  <template #post-icon>
                    <GeolocateButton
                      ref="geolocateButton"
                      data-ui="search-banner-geolocation-btn"
                      google-api-key="AIzaSyAIG3db8X-64gITBmCx7WekuKrYrOLvg7k"
                      @geolocation-status="onGeolocationStatus"
                      @geocoded-geolocation="onGeocodedGeolocation"
                    />
                  </template>
                </AutocompleteInput>
                <StatusMessages
                  :status="statusMessage"
                  data-ui="geolocate-status-messages"
                />
              </div>
            </div>
            <div class="form-group search-banner-distance">
              <CdrSelect
                v-model="distance"
                data-ui="search-banner-distance"
                label="Distance"
                name="distance"
                background="secondary"
              >
                <option
                  v-for="(d, index) in props.distances"
                  :key="index"
                  :value="d"
                  v-html="`Within ${d} miles`"
                />
              </CdrSelect>
            </div>
            <div class="form-group">
              <CdrButton
                :full-width="true"
                class="search-banner__button"
                data-ui="search-banner-submit"
                type="submit"
                modifier="secondary"
              >
                Search
              </CdrButton>
            </div>
          </form>
        </div>
      </div>
    </div>
    <CdrImg
      src="https://satchel.rei.com/media/img/powered_by_google_on_non_white.png"
      alt="Powered by Google"
      class="search-banner__powered-by-google"
    />
  </section>
</template>

<style lang="scss">
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

$experiences-brand-background-color: #13352c;

.search-banner {
  background-image: var(--background-image-sm, var(--background-image-md));
  background-size: cover;
  background-position: center;
  padding-bottom: $cdr-space-two-x;
  position: relative;
  height: 400px;

  @include cdr-sm-mq-up {
    background-image: var(--background-image-md);
    padding-bottom: 0;
  }

  @include cdr-xs-mq-only {
    height: fit-content;
  }

  &__powered-by-google {
    bottom: $cdr-space-one-x;
    position: absolute;
    right: 50%;
    transform: translateX(50%);

    @include cdr-sm-mq-up {
      right: $cdr-space-one-x;
      transform: none;
    }
  }

  &-copy {
    color: $cdr-color-text-inverse;
    text-align: center;
    padding-top: $cdr-space-one-x;
    padding-bottom: $cdr-space-one-and-a-half-x;

    @include cdr-sm-mq-up {
      padding-top: $cdr-space-two-x;
    }
  }

  &__headline {
    text-align: center;
    margin-top: 0;
    margin-bottom: $cdr-space-half-x;
    padding: 0 4rem;
    @include cdr-text-heading-serif-900;

    @include cdr-xs-mq-only {
      padding: 0 $cdr-space-one-x;
    }
  }

  &-body-text {
    color: $cdr-color-text-inverse;
    text-align: center;
    margin: 0;
    @include cdr-text-body-400;

    @include cdr-xs-mq-only {
      padding: 0 $cdr-space-one-x;
    }
  }

  &__button {
    height: 4.6rem;
    padding: $cdr-space-three-quarter-x;

    @include cdr-sm-mq-up {
      border-radius: 0;
    }
  }

  &-location {
    .input-group {
      flex-direction: column;

      @include cdr-sm-mq-up {
        flex-direction: row;
      }
    }

    .status-messages {
      position: static;
      border-style: solid;

      @include cdr-sm-mq-up {
        position: absolute;
      }
    }
  }

  &-form {
    margin: 0 auto;
    width: fit-content;
    padding: $cdr-space-one-x;
    border-radius: $cdr-radius-softer;
    align-items: flex-end;
    background-color: $experiences-brand-background-color;

    @include cdr-sm-mq-up {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: $cdr-space-one-x $cdr-space-two-x $cdr-space-two-x;
    }

    @include cdr-xs-mq-only {
      margin-bottom: $cdr-space-one-and-a-half-x;
      width: 100%;
      max-width: 350px;
    }

    select,
    input {
      box-shadow: none;
    }

    select,
    input,
    input:focus {
      height: 4.6rem;
      border-radius: 0;
      border: 1px solid #dcd6cb;
      background-color: $cdr-color-background-primary;

      @include cdr-text-utility-sans-300;
    }

    input:focus,
    input:active {
      box-shadow: inset 0 0 0 0.2rem #20201d, 0 0.2rem 0.2rem 0 rgb(12 11 8 / 20%);
    }

    input {
      &::placeholder {
        font-style: italic;
      }
    }

    svg {
      width: 1.7rem;
      height: 1.7rem;
    }

    .form-group {
      margin-bottom: 0.8rem;

      label {
        display: block;
        margin: 0;
        color: $cdr-color-text-inverse;
      }

      .field-msg_error {
        display: none;
      }

      &.has-error {
        .search-banner-locate {
          border-color: $cdr-color-border-error;
        }

        .field-msg_error {
          display: block;
        }
      }

      .input-group {
        display: flex;
        border-radius: 0.4rem;
        position: relative;

        div {
          &.form-control {
            border-left: 1px solid $cdr-color-border-secondary;
            border-right: 1px solid $cdr-color-border-secondary;
            padding: 0;
          }
        }
      }

      .geolocate-btn {
        background-color: $cdr-color-background-primary;
        box-shadow: none;
        margin-right: -0.5rem;
        padding: 0 0.8rem;

        &:hover,
        &:focus {
          background-color: $cdr-color-background-primary;
          box-shadow: none;
          padding: 0 0.8rem;
        }
      }

      .autocomplete-list {
        z-index: 1;
      }

      @include cdr-sm-mq-up {
        margin-right: 0;

        &.search-banner-activity,
        &.search-banner-distance {
          position: relative;
          width: 20rem;

          select {
            line-height: $cdr-space-two-x;
            position: relative;

            &::-ms-expand {
              display: none;
            }
          }
        }

        .search-banner-location {
          // hide select arrow on ie11
          & select::-ms-expand {
            display: none;
          }

          & .cdr-select-post-icon {
            position: absolute;
            right: 1.4rem;
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        /* Helpers */
        .relative {
          position: relative;
        }

        .form-control {
          border-radius: 0;
          height: 4.5rem;

          &[name='activity'] {
            border-bottom-left-radius: 0.3rem;
            border-right-width: 0;
            border-top-left-radius: 0.3rem;
            padding-left: 1rem;
          }

          &[name='distance'] {
            border-left-width: 0;
            padding-left: 1rem;
          }
        }

        .search-banner-locate {
          border-radius: 0;
        }
      }
    }
  }

  &-location__autocomplete-wrapper {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .row {
    background-color: $experiences-brand-background-color;
    border-radius: $cdr-radius-softer;
    margin: $cdr-space-one-x;
  }
}
</style>
