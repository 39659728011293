<script lang="ts" setup>
import { computed, inject } from 'vue';
import { CdrCard, CdrText, CdrLink } from '@rei/cedar';
import ResponsiveImage from '../global/contentstack/ResponsiveImage.vue';
import type { Image, Link } from '../../types/contentstack.types';
import getDataUi from '../../common/getDataUi.mjs';
import { type Analytics } from '../../types/shared.types.mjs';

defineOptions({ name: 'FeaturedExperience', inheritAttrs: false });

export interface FeaturedExperienceProps {
  heading: string;
  description: string;
  image: Image;
  link: Link;
  dataUi?: string;
}

const props = withDefaults(defineProps<FeaturedExperienceProps>(), {
  dataUi: 'featured-experience',
});

const analytics = inject<Analytics>('analytics');

const imageSizes = computed(() => ({
  xs: '100vw',
  sm: '50vw',
  md: '25vw',
  lg: '12.5vw',
}));
const dataUi = computed(() => ({
  image: getDataUi(props.dataUi, 'image'),
  heading: getDataUi(props.dataUi, 'heading'),
  description: getDataUi(props.dataUi, 'description'),
  link: getDataUi(props.dataUi, 'link'),
}));
</script>

<template>
  <CdrCard
    :title="props.heading"
    :description="props.description"
    tag="li"
    :data-ui="props.dataUi"
  >
    <ResponsiveImage
      container-class="featured-experience__img"
      :image="props.image"
      :data-ui="dataUi.image"
      :sizes="imageSizes"
    />
    <CdrLink
      class="featured-experience__link cdr-card__link"
      :href="props.link.href"
      :data-analytics-id="analytics?.getId('FeaturedExperience', props.heading)"
      :data-ui="dataUi.link"
    >
      <CdrText
        class="featured-experience__heading"
        tag="h3"
        :data-ui="dataUi.heading"
      >
        {{ props.heading }}
      </CdrText>
      <CdrText
        class="featured-experience__description"
        tag="p"
        :data-ui="dataUi.description"
      >
        {{ props.description }}
      </CdrText>
    </CdrLink>
  </CdrCard>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

.featured-experience {
  &__img {
    display: block;
    --responsive-image-height: 155px;
  }

  &__heading {
    &#{&} {
      @include cdr-text-heading-serif-600;

      &:not(:first-child) {
        margin-top: $cdr-space-one-x;
      }
    }
  }

  &__body {
    &#{&} {
      @include cdr-text-body-400;

      &:not(:first-child) {
        margin-top: $cdr-space-half-x;
      }
    }
  }

  &__link {
    display: block;
    padding: $cdr-space-one-x;

    &:hover {
      text-decoration: none !important;
    }
  }
}
</style>
