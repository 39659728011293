<script lang="ts" setup>
/**
 * A component to show rows of icons with text below.
 * @displayName CategoryIcons
 */
import { computed, inject } from 'vue';
import { CdrText, CdrLink } from '@rei/cedar';
import getDataUi from '../../common/getDataUi.mjs';
import { categoryIcons } from './data.mjs';
import { type Analytics } from '../../types/shared.types.mjs';

defineOptions({ name: 'CategoryIcons', inheritAttrs: false });

export interface CategoryIconsProps {
  dataUi?: string;
}

const props = withDefaults(defineProps<CategoryIconsProps>(), {
  dataUi: 'category-icons',
});

const analytics = inject<Analytics>('analytics');

const dataUi = computed(() => ({
  heading: getDataUi(props.dataUi, 'heading'),
  list: getDataUi(props.dataUi, 'list'),
  link: getDataUi(props.dataUi, 'link'),
  title: getDataUi(props.dataUi, 'title'),
}));
</script>

<template>
  <section
    id="category-icons"
    class="category-icons"
    :data-ui="props.dataUi"
  >
    <CdrText
      class="category-icons__heading"
      tag="h2"
      :data-ui="dataUi.heading"
    >
      Browse by category
    </CdrText>
    <ul
      class="category-icons__list"
      :data-ui="dataUi.list"
    >
      <li
        v-for="(category, index) in categoryIcons"
        :key="`${category.link.title}${index}`"
        class="category-icons__item"
      >
        <CdrLink
          class="category-icons__link"
          :href="category.link.href"
          :data-ui="dataUi.link"
          :data-analytics-id="analytics?.getId('CircleIcons', category.link.title)"
        >
          <component
            :is="category.icon"
            class="category-icons__icon"
          />
          <CdrText
            class="category-icons__title"
            tag="h3"
            :data-ui="dataUi.title"
          >
            {{ category.link.title }}
          </CdrText>
        </CdrLink>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
@import '@rei/cdr-tokens/dist/rei-dot-com/scss/cdr-tokens';

.category-icons {
  max-width: 950px;
  text-align: center;

  &__heading {
    @include cdr-text-utility-sans-strong-500;
  }

  &__list {
    margin: $cdr-space-one-x 0 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__link {
    width: 145px;
    display: block;
    padding: $cdr-space-one-x 0;
    text-decoration: none;

    &:hover {
      .category-icons__title {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
  }

  &__title {
    @include cdr-text-body-300;
    margin-top: $cdr-space-half-x;
    color: $cdr-color-text-primary;
  }
}
</style>
